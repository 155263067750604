/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import ObjectWrapper from '../Components/ObjectWrapper'

const obj = {
  title: 'Toothbrush',
  desc: 'Tool to paint image and patterns with the chemistry.'
}

export default function Toothbrush({ distance, ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/assets/objects/toothbrush.glb')
  return (
    <ObjectWrapper {...props} dispose={null} obj={obj} actDistance={distance}>
      <group rotation={[Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes.Body124.geometry} material={materials['Steel - Satin']} />
        <group active={true}>
          <mesh geometry={nodes.Body10.geometry} material={materials['Plastic - Matte (Black)']} />
          <mesh geometry={nodes.Body105.geometry} material={materials['Plastic - Matte (Black)']} />
          <mesh geometry={nodes.Body121.geometry} material={materials['Plastic - Glossy (Yellow)']} />
          <mesh geometry={nodes.Body124.geometry} material={materials['Steel - Satin']} />
          <mesh geometry={nodes.Body127.geometry} material={materials['ABS (White)']} />
          <mesh geometry={nodes.Body176.geometry} material={materials['ABS (White)']} />
          <mesh geometry={nodes.Body177.geometry} material={materials['Plastic - Matte (Blue)']} />
          <mesh geometry={nodes.Body229.geometry} material={materials['Plastic - Matte (Blue)']} />
        </group>
      </group>
    </ObjectWrapper>
  )
}

useGLTF.preload('/assets/objects/toothbrush.glb')
