/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import ObjectWrapper from '../Components/ObjectWrapper'

const obj = {
    title: 'Flash light',
    desc: 'Different types of flash light can be used as light source and allow to have a good control over direction and focus.'
}

export default function FlashLight2({distance, ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/assets/objects/flash_light_2.glb')

  const materialProps = {
    thickness: 1,
    roughness: 0.2,
    clearcoat: 1,
    clearcoatRoughness: 0.1,
    transmission: 0.9,
    ior: 1.5,
    envMapIntensity: 1,
    color: '#ffffff',
    attenuationTint: '#000000',
    attenuationDistance: 0
  }

  materials.Body.emissiveIntensity = 0
  materials.Body.roughness = 0.3

  return (
    <ObjectWrapper {...props} dispose={null} obj={obj} actDistance={distance}>
      <mesh geometry={nodes.Flashlight.geometry} material={materials.Body} position={[0, 0, 0.7]} rotation={[-Math.PI / 2, 1.57, 0]} scale={0.2} />
      <mesh geometry={nodes.Flashlight_button.geometry} material={materials.Body} position={[0, 0, 0.7]} rotation={[-Math.PI / 2, 1.57, 0]} scale={0.2} />
      <mesh geometry={nodes.Glass.geometry} position={[0, 0, 0.93]} rotation={[-Math.PI / 2, 1.57, 0]} scale={0.2}>
        <meshPhysicalMaterial {...materialProps} />
      </mesh>
        <pointLight
            position={[0, 0, 2]}
            distance={30}
            decay={3}
            color={[1, 0, 0]}
        />
      <mesh
        geometry={nodes.LED_reflector.geometry}
        material={materials['Spring&LED reflector']}
        position={[0, 0, 0.7]}
        rotation={[-Math.PI / 2, 1.57, 0]}
        scale={0.2}
      />
      <mesh geometry={nodes.LED.geometry} material={materials.LED} position={[0, 0, -1.12]} rotation={[Math.PI / 2, 0, 0]} scale={0.2} />
    </ObjectWrapper>
  )
}

useGLTF.preload('/assets/objects/flash_light_2.glb')
