import { FlyControls, OrbitControls } from '@react-three/drei'
import {useEffect, useRef, useState} from 'react'

function Control({setParControl, ...props}) {

  const orbitControl = useRef()
  const flyControl = useRef()

  const [control, setControl] = useState(orbitControl)
  const [contType, setContrType] = useState(true)

    useEffect(()=>{
        setParControl(control)
    },[control])

  function changeControl() {
    setControl(contType ? orbitControl : flyControl)
    setContrType(!contType)
  }

  return <>{contType ? <OrbitControls makeDefault ref={orbitControl} /> : <FlyControls makeDefault movementSpeed={10} ref={flyControl} />}</>
}

export default Control
