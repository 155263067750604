import ModalInfo from './Modal/ModalInfo'
import React, { useEffect, useRef, useState } from 'react'
import { Vector3 } from 'three'
import Perlin, { checkAngleToObjectFromCameraView, checkDistanceToCamera } from '../lib/helpers'
import { useFrame } from '@react-three/fiber'

const max_speed = 1
const scale = 50

function ObjectWrapperPassive({ children, speed, ...props }) {
  const group = useRef()

    let time = 0

  const perlin = new Perlin()

  useFrame(async (state, delta) => {

      time += delta *.01
    // group.current.position.x += perlin.noise(group.current.position.x, speed.x)
    // group.current.position.y += perlin.noise(group.current.position.y/scale, group.current.position.z/scale) * max_speed
    // group.current.position.z += perlin.noise(group.current.position.z/scale, group.current.position.x/scale) * max_speed
  })

  return (
    <group {...props} ref={group}>
      {children}
    </group>
  )
}

export default ObjectWrapperPassive
