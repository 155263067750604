import { useBounds } from '@react-three/drei'
import { useEffect } from 'react'

// TODO: ok for initial, tho could be better camera transition to there
function Lost({ lost, setLost, camera }) {
  const api = useBounds()

  useEffect(() => {
    const target = camera.current.closestObject
    // console.log(target?.current)
    if (target !== null) {
      if (!target.current.active) api.refresh(target.current.children[0]).fit()
    }
    setLost(false)
  }, [lost])

  return <></>
}

export default Lost
