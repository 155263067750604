import React, { useRef, useState } from 'react'
import { useGLTF } from '@react-three/drei'
import ObjectWrapper from '../Components/ObjectWrapper'

const obj = {
  title: 'Laser pointer',
  desc: 'Even more interesting source of highly focused light source and intensity. Great control and power requires more accuracy and passion. Good for creating sharp patterns.'
}

export default function Laser({ distance, ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/assets/objects/laser.glb')

  const [state, setState] = useState(1)

  function switchLaser() {
    setState(1 - state)
  }

  materials['LaserOutside.001'].transparent = true
  materials['LaserOutside.001'].opacity = 0.01 * state
  materials['LaserOutside.001'].emissiveIntensity = 10
  materials.LaserOutside.transparent = true
  materials.LaserOutside.opacity = 0.05 * state
  materials.LaserOutside.emissiveIntensity = 3

  materials['Material.001'].color.r = 0.2
  materials['Material.001'].color.g = 0.2
  materials['Material.001'].color.b = 0.2

  return (
    <ObjectWrapper {...props} dispose={null} obj={obj} actDistance={distance}>
      <group active={true}>
        <mesh geometry={nodes.lasertInside.geometry} material={materials.LaserOutside} position={[0, 18.96, 0]} scale={[-0.02, -17.22, -0.02]} />
        <mesh geometry={nodes.lasertInside.geometry} material={materials.LaserOutside} position={[0, 18.96, 0]} scale={[-0.04, -17.22, -0.04]} />
        <mesh
          geometry={nodes.LaserOutside.geometry}
          material={materials['LaserOutside.001']}
          position={[0, 18.96, 0]}
          scale={[0.05, -17.22, 0.05]}
          active={true}
        />
      </group>

      <group rotation={[-Math.PI, -0.71, -Math.PI]} scale={[0.18, 0.29, 0.18]} onClick={switchLaser}>
        <mesh geometry={nodes.Circle_1.geometry} material={materials['Material.001']} />
        <mesh geometry={nodes.Circle_2.geometry} material={materials['Material.002']} />
        <group active={true}>
          <mesh geometry={nodes.Circle_3.geometry} material={materials['Material.003']} />
        </group>
      </group>
    </ObjectWrapper>
  )
}

useGLTF.preload('/assets/objects/laser.glb')
