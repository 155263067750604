import { Suspense, cloneElement, useEffect, useState } from 'react'
import { Container, Image, Text, Title, TypographyStylesProvider } from '@mantine/core'

function Ready({ setReady }) {
  useEffect(() => () => void setReady(true), [])
  return null
}

function toTimestamp(strDate){
  var datum = Date.parse(strDate)
  return datum
}

function dateToStr(today){
  return Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(today)
}

const openTime = toTimestamp("4 June 2022 00:00:00 GMT") - 8 * 60 * 60 * 1000
const closeTime = toTimestamp("6 June 2022 00:00:00 GMT") - 8 * 60 * 60 * 1000
const td = new Date();
const timeOffset = td.getTimezoneOffset()
const today = Date.now() + timeOffset * 60 * 1000

export default function Intro({ children }) {

  const [exhOpen, setExhOpen] = useState(today > openTime)
  const [dropOpen, setDropOpen] = useState(today < closeTime && exhOpen)

  const [clicked, setClicked] = useState(false)
  const [ready, setReady] = useState(false)

  // developing of the image
  let opacity = 0
  const [imgOpacity, setImgOpacity] = useState(0.01)
  let interval

  useEffect(() => {
    interval = setInterval(() => {
      if (opacity < 1) {
        opacity = opacity + 0.05
      } else {
        clearInterval(interval)
      }
      setImgOpacity(opacity)
    }, 10)
    return () => clearInterval(interval)
  }, [])

  function enterHandler() {
    if (ready) {
      if(exhOpen){
        opacity = 1.1
        setClicked(true)
        clearInterval(interval)
      }else{
        console.log("Not open yet")
      }
    }
  }

  return (
    <>
      <Suspense fallback={<Ready setReady={setReady} />}>{cloneElement(children, { ready: clicked && ready, drop: dropOpen })}</Suspense>
      <div className={`fullscreen bg ${ready ? 'ready' : 'notready'} ${clicked && 'clicked'}`}>
        <div className="stack">
          <TypographyStylesProvider>
            <Title order={1}><span style={{color: "#3399ff"}}>POLAROIDS </span><span style={{fontSize: 18, color: "#999999"}}>June 4th - End of the internet</span></Title>
            <Text size="lg">Virtual interactive exhibition for Polaroids collection. Explore process and context of the works and artist practice.</Text>
            <Container className="mainImage" onClick={enterHandler}>
              <Image radius="md" width={600} src="/assets/frame.jpg" alt="Polaroid series" shadows="lg" className="mainFrame" />
              <Image
                radius="md"
                width={600}
                src="/assets/main.jpg"
                alt="Polaroid series"
                style={{ opacity: `${imgOpacity}` }}
                shadows="lg"
                className="PImage"
              />
            </Container>
            <Text size="lg">
              The Polaroid series is a way to express and explore the analog way of creating generative works using light and different materials, embracing the
              uncertainty of random events and meditative processes.
            </Text>
            <br />
            <Text size="lg" weight={700}>
              {exhOpen ?
                  (<a href="#" onClick={enterHandler}>
                    {!ready ? 'Loading...' : 'EXPLORE'}
                  </a>)
                  :
                  (<Text> Exhibition will open from June 4th, 00:00 GMT</Text>)
              }

            </Text>
          </TypographyStylesProvider>
        </div>
      </div>
    </>
  )
}
