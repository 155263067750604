/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import ObjectWrapper from "../Components/ObjectWrapper";

const obj = {
  title: 'Paint brush',
  desc: 'Paint brush one of the element used to control chemical development reaction. In addition to brush was used different sponge, cramp paper, brushes and fabric.'
}

export default function PaintBrush({ distance, ...props }) {

  const group = useRef()
  const { nodes, materials } = useGLTF('/assets/objects/paint_brush.glb')

  return (
    <ObjectWrapper {...props} dispose={null} obj={obj} actDistance={distance}>
      <mesh geometry={nodes.Paintbrush.geometry} material={materials['Material.004']} rotation={[Math.PI / 2, 0, 0]} />
    </ObjectWrapper>
  )
}

useGLTF.preload('/assets/objects/paint_brush.glb')
