/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import ObjectWrapper from '../Components/ObjectWrapper'

const obj = {
  title: 'Prism',
  desc: 'Prisms are more sophisticated and scientific object that allow to control the light direction, intensity and spectra.'
}

export default function Prism1({ distance, ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/assets/objects/prism_1.glb')

  const materialProps = {
    thickness: 10,
    roughness: 0,
    clearcoat: 0,
    clearcoatRoughness: 0.6,
    transmission: 0.97,
    ior: 5,
    envMapIntensity: 0.07,
    color: '#777777',
    attenuationTint: '#ff0000',
    attenuationDistance: 5
  }

  return (
    <ObjectWrapper {...props} dispose={null} obj={obj} actDistance={distance}>
      <mesh geometry={nodes.Objekt_1.geometry}>
        <meshPhysicalMaterial {...materialProps} />
      </mesh>
    </ObjectWrapper>
  )
}

useGLTF.preload('/assets/objects/prism_1.glb')
