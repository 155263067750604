import {CameraIcon, CheckIcon, CropIcon, GearIcon, InfoCircledIcon, PersonIcon} from '@radix-ui/react-icons'
import {Center, Container, Notification, Tooltip} from '@mantine/core'
import ModalProject from '../Modal/ModalProject'
import { useEffect, useState } from 'react'
import ModalControl from '../Modal/ModalControl'
import ModalPerson from '../Modal/ModalPerson'
import { useBounds } from '@react-three/drei'
import ModalCatalogue from "../Modal/ModalCatalogue";
import ModalDrop from "../Modal/ModalDrop";
import ModalResult from "../Modal/ModalResult";

function Menu({ ready, setWindow, setLost, isMobile, drop, ...props }) {

  const [infoOpened, setInfoOpened] = useState(false)
  const [controlOpened, setControlOpened] = useState(false)
  const [personOpened, setPersonOpened] = useState(false)
  const [catalogOpened, setCatalogOpened] = useState(false)
  const [dropOpened, setDropOpened] = useState(drop)
  const [resultOpenned, setResultOpenned] = useState(false)
  const [success, setSuccess] = useState(undefined)

  useEffect(() => {
    if (ready) setControlOpened(true)
  }, [ready])

  useEffect(() => {
    setWindow(controlOpened)
  }, [controlOpened])

  function searchHandler() {
    setLost(true)
  }

  return (
    <>
      <Container className="controls">
        <Center className="btn">
          <Tooltip label="Search closest">
            <CropIcon className="svg-icon" onClick={searchHandler} />
          </Tooltip>
        </Center>
        <Center className="btn">
          <Tooltip label="Polaroids catalogue">
            <CameraIcon
              className="svg-icon"
              onClick={() => {
                setCatalogOpened(!catalogOpened)
              }}
            />
          </Tooltip>
        </Center>
        <Center className="btn">
          <Tooltip label="Settings and help">
            <GearIcon
              className="svg-icon"
              onClick={() => {
                setControlOpened(!controlOpened)
              }}
            />
          </Tooltip>
        </Center>
        <Center className="btn">
          <Tooltip label="Artist introduction">
            <PersonIcon
              className="svg-icon"
              onClick={() => {
                setPersonOpened(!personOpened)
              }}
            />
          </Tooltip>
        </Center>
        <Center className="btn">
          <Tooltip label="Project introduction">
            <InfoCircledIcon
              className="svg-icon"
              onClick={() => {
                setInfoOpened(!infoOpened)
              }}
            />
          </Tooltip>
        </Center>
      </Container>
      <ModalProject opened={infoOpened} setOpened={setInfoOpened} />
      <ModalControl opened={controlOpened} setOpened={setControlOpened} {...props} />
      <ModalPerson opened={personOpened} setOpened={setPersonOpened} />
      <ModalCatalogue opened={catalogOpened} setOpened={setCatalogOpened} />
      <ModalDrop  opened={dropOpened} setOpened={setDropOpened} success={setSuccess} result={setResultOpenned} />
      <ModalResult  opened={resultOpenned} setOpened={setResultOpenned} success={success} isMobile={isMobile} />
    </>
  )
}

export default Menu
