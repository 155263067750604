/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import ObjectWrapper from "../Components/ObjectWrapper";

const obj = {
  title: 'Broken glass',
  desc: 'Pieces of broken glass was used a lot to create multiple layers of reflection as they can be combined with each other.'
}

export default function BrokenGlass2({distance, ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/assets/objects/broken_glass_2.glb')

  const materialProps = {
    thickness: 2,
    roughness: 0.2,
    clearcoat: 1.0,
    clearcoatRoughness: 0.1,
    transmission: 0.99,
    ior: 1.9,
    envMapIntensity: 2,
    color: '#88ff99',
    attenuationTint: '#000000',
    attenuationDistance: 0
  }

  return (
    <ObjectWrapper {...props} dispose={null} obj={obj} actDistance={distance}>
      <mesh geometry={nodes.beach_glass.geometry} >
        <meshPhysicalMaterial {...materialProps} />
      </mesh>
    </ObjectWrapper>
  )
}

useGLTF.preload('/assets/objects/broken_glass_2.glb')
