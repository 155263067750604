/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/
import * as THREE from 'three'
import React, {useRef, useState} from 'react'
import { useGLTF } from '@react-three/drei'
import ObjectWrapper from '../Components/ObjectWrapper'

const obj = {
  title: 'Phone',
  desc: 'Mobile phone could be source of the light. At the same time it is the tool to document the process and control time.'
}

export default function Phone({ distance, ...props }) {

  const group = useRef()
  const { nodes, materials } = useGLTF('/assets/objects/phone.glb')
  const [video] = useState(() => Object.assign(document.createElement('video'), { src: '/assets/video/polaroid_1.mp4', crossOrigin: 'Anonymous', loop: true, preload: true }))

  const [on, setOn] = useState(0)
  const [play, setPlay] = useState(false)
  const [screenOpacity, setScreenOpacity] = useState(0.01)

  materials.SmartPhone_Screnn.emissiveIntensity = on
  materials.SmartPhone_Screnn.color.r = 0
  materials.SmartPhone_Screnn.color.g = 0
  materials.SmartPhone_Screnn.color.b = 0

  function clickHandler() {
    setOn(!on)
    on ? stopPlayback() : startPlayback()
  }

  //interaction with object
  async function startPlayback() {
    // first check if it is not on already
    if (!play) {

      await video.play()
      await setPlay(true)
      setScreenOpacity(1)
    }
  }
  async function stopPlayback() {
    // check if it's playing or not
    if (play) {
      await video.pause()
      await setPlay(false)
      setScreenOpacity(0.01)
    }
  }

  return (
    <ObjectWrapper {...props} dispose={null} obj={obj} actDistance={distance}>
      <mesh geometry={nodes.SmartPhone_Body.geometry} material={materials['SmartPhone_back.001']} onClick={clickHandler}>
        <mesh
          geometry={nodes.SmartPhone_back.geometry}
          material={materials['SmartPhone_back.001']}
          position={[1.45, -0.48, -9.43]}
          rotation={[-Math.PI, 0, 0]}
          scale={[0.03, 0.06, 0.03]}
        />
        <group position={[1.89, -0.69, -9.85]} rotation={[-Math.PI, 0, 0]} scale={[6.97, 16.41, 6.44]} active={true}>
          <mesh geometry={nodes.Cylinder002.geometry} material={materials['SmartPhone_Glass_camera_small_in.001']} />
          <mesh geometry={nodes.Cylinder002_1.geometry} material={materials['SmartPhone_camera_in_edge_A.001']} />
          <mesh geometry={nodes.Cylinder002_2.geometry} material={materials['SmartPhone_camera_in_edge_B.001']} />
        </group>
        <group position={[1.89, -0.69, -8.77]} rotation={[-Math.PI, 0, 0]} scale={[6.97, 16.41, 6.44]} active={true}>
          <mesh geometry={nodes.Cylinder025.geometry} material={materials['SmartPhone_Glass_camera_small_in.001']} />
          <mesh geometry={nodes.Cylinder025_1.geometry} material={materials['SmartPhone_camera_in_edge_A.001']} />
          <mesh geometry={nodes.Cylinder025_2.geometry} material={materials['SmartPhone_camera_in_edge_B.001']} />
        </group>
        <group active={true}>
          <mesh
            geometry={nodes.SmartPhone_edge_camera_base.geometry}
            material={materials['SmartPhone_back.001']}
            position={[1.9, -0.5, -9.31]}
            scale={[5.81, 13.69, 5.37]}
          />
          <mesh
            geometry={nodes.SmartPhone_edge_camera001.geometry}
            material={materials['SmartPhone_Small camera edge.002']}
            position={[1.89, -0.53, -9.85]}
            rotation={[-Math.PI, 0, 0]}
            scale={[6.39, 8.53, 5.9]}
          />
          <mesh
            geometry={nodes.SmartPhone_edge_camera002.geometry}
            material={materials['SmartPhone_Small camera edge.002']}
            position={[1.89, -0.53, -8.76]}
            rotation={[-Math.PI, 0, 0]}
            scale={[6.39, 8.53, 5.9]}
          />
        </group>
        <group position={[1.45, -0.41, -9.27]} rotation={[-Math.PI, 0, 0]} scale={[0.08, 0.19, 0.08]} active={true}>
          <mesh geometry={nodes.Cylinder012.geometry} material={materials.SmartPhone_flash_yellow} />
          <mesh geometry={nodes.Cylinder012_1.geometry} material={materials.SmartPhone_flash_white} />
        </group>

        <group position={[0.01, 0.37, -8.35]}>
          <mesh geometry={nodes.Plane007.geometry} material={materials['SmartPhone_back.001']} />
          <mesh geometry={nodes.Plane007_1.geometry} position={[0,-.1,0]} >
            <meshPhysicalMaterial
                color={[0,0,0]}
                clearcoat={1}
                clearcoatRoughness={0}
                toneMapped={false}
                emissiveIntensity={0}
                emissive={[0, 0, 0]} />
          </mesh>
          <mesh geometry={nodes.Plane007_1.geometry} >
            <meshPhysicalMaterial
                clearcoat={1}
                clearcoatRoughness={0}
                toneMapped={false}
                transparent={true}
                opacity={screenOpacity}
                emissiveIntensity={1}
                emissive={[1, 0.8, 0.5]}>
            <videoTexture
                key={1}
                attach="map"
                args={[video]}
                flipY={false}
                repeat={[1, 1]}
                offset={[0, 0]}
                wrapT={THREE.RepeatWrapping}
                wrapS={THREE.RepeatWrapping}
                encoding={THREE.sRGBEncoding}
            />
            <videoTexture
                key={2}
                attach="emissiveMap"
                args={[video]}
                flipY={false}
                repeat={[1, 1]}
                offset={[0, 0]}
                wrapT={THREE.RepeatWrapping}
                wrapS={THREE.RepeatWrapping}
                encoding={THREE.sRGBEncoding}
            />
            </meshPhysicalMaterial>
          </mesh>
          <group active={true}>
            <mesh geometry={nodes.Plane007_2.geometry} material={materials.SmartPhone_Speaker} />
          </group>
        </group>
        <group active={true}>
          <mesh
            geometry={nodes.SmartPhone_Switch001.geometry}
            material={materials['SmartPhone_back.001']}
            position={[-2.96, -0.01, -8.75]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={[4.78, 2.28, 10.14]}
          />
          <mesh
            geometry={nodes.SmartPhone_Switch002.geometry}
            material={materials['SmartPhone_back.001']}
            position={[-2.95, 0, -7.65]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={[5.81, 5.37, 13.69]}
          />
        </group>
      </mesh>
    </ObjectWrapper>
  )
}

useGLTF.preload('/assets/objects/phone.glb')
