/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { Box, useGLTF } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import { Vector3 } from 'three'
import ObjectWrapper from '../Components/ObjectWrapper'

const obj = {
  title: 'Flash light',
  desc: 'Different types of flash light can be used as light source and allow to have a good control over direction and focus.'
}

export default function FlashLight1({ distance, ...props }) {
  const group = useRef()

  const target = useRef()
  const { nodes, materials } = useGLTF('/assets/objects/flash_ligh_1.glb')

  const materialProps = {
    thickness: 1,
    roughness: 0.2,
    clearcoat: 1,
    clearcoatRoughness: 0.1,
    transmission: 0.9,
    ior: 1.5,
    envMapIntensity: 1,
    color: '#ffffff',
    attenuationTint: '#000000',
    attenuationDistance: 0
  }

  return (
    <ObjectWrapper {...props} dispose={null} obj={obj} actDistance={distance}>
      <group position={[0, 0, 0]} scale={0.06} ref={target}>
        <group active={true}>
          <mesh
            geometry={nodes.Cylinder003.geometry}
            material={materials['Material #29']}
            position={[0, 0, 85.91]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={[1.22, 1.65, 1.22]}
          />
          <group position={[0, 0, -86.96]} rotation={[Math.PI / 2, 0, 0]} scale={[1.01, 1, 1.01]}>
            <mesh geometry={nodes.Mesh021.geometry} material={materials['Material #29']} />
            <mesh geometry={nodes.Mesh021_1.geometry} material={materials['Material #33']} />
          </group>
          <group position={[0, 0, -90.73]} scale={[0.94, 0.94, 0.39]}>
            <mesh geometry={nodes.GeoSphere002.geometry}>
              <meshPhysicalMaterial {...materialProps} />
            </mesh>
            <pointLight position={[0, 0, 10]} distance={30} decay={3} color={[1, 0, 0]} />
          </group>
          <mesh
            geometry={nodes.Helix002.geometry}
            material={materials['Material #26']}
            position={[0, 0.13, 95.15]}
            rotation={[1.4, 0, 0]}
            scale={[0.49, 0.37, 0.49]}
          />
          <mesh
            geometry={nodes.Object006.geometry}
            material={materials['Material #29']}
            position={[0, 0, 85.15]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={[1.22, 1.65, 1.22]}
          />
          <mesh geometry={nodes.Object007.geometry} material={materials['Material #29']} position={[16.75, 0, -20.41]} scale={[0.88, 0.88, 1]} />
          <mesh
            geometry={nodes.Tube002.geometry}
            material={materials['Material #29']}
            position={[0.84, 15.86, 92.89]}
            rotation={[Math.PI, 0, -Math.PI / 2]}
            scale={[1.06, 0.45, 1]}
          />
        </group>

        <group position={[16.75, 0, -20.41]} scale={[0.88, 0.88, 1]}>
          <mesh geometry={nodes.Mesh019.geometry} material={materials['Material #29']} />
          <mesh geometry={nodes.Mesh019_1.geometry} material={materials['Material #30']} />
        </group>
      </group>
    </ObjectWrapper>
  )
}

useGLTF.preload('/assets/objects/flash_ligh_1.glb')
