import { Modal, Button, Group, Title, Text, List, Center, Container, TypographyStylesProvider, Blockquote, Image, Input, Stack } from '@mantine/core'
import { CheckIcon, Cross1Icon, DiscordLogoIcon, Link2Icon, PaperPlaneIcon, TwitterLogoIcon } from '@radix-ui/react-icons'
import { useEffect, useState } from 'react'
import TezosIcon from '../tezosIcon'
import { showNotification } from '@mantine/notifications'

const Airtable = require('airtable')
Airtable.configure({
  endpointUrl: 'https://api.airtable.com',
  apiKey: 'keyUGRCD90ggK1M57'
})
const base = Airtable.base('appV4ZrZvnRDXV8Ob')
const queryParams = new URLSearchParams(window.location.search)

function ModalDrop({ opened, setOpened, result, success }) {
  const [address, setAddress] = useState('none')
  const [name, setName] = useState('none')
  const [shared, setShared] = useState(queryParams.get('link'))
  const [errorAd, setErrorAd] = useState(false)
  const [errorName, setErrorName] = useState(false)

  function createRecord() {
    let next = true

    if (address !== 'none') {
      setErrorAd(false)
    } else {
      setErrorAd(true)
      next = false
    }

    if (name !== 'none') {
      setErrorName(false)
    } else {
      setErrorName(true)
      next = false
    }

    if (next) {
      base('Drop addresses').create(
        [
          {
            fields: {
              'wallet address': address,
              name: name,
              shared: shared
            }
          }
        ],
        function (err, records) {
          if (err) {
            setOpened(false)
            showNotification({
              title: 'Error',
              message: 'Something went wrong. Please reload page and try again.',
              color: 'red',
              icon: <Cross1Icon />
            })
            return
          }
          records.forEach(function (record) {
            success(record.fields.id)
            setOpened(false)
            result(true)
            showNotification({
              title: 'Success',
              message: 'Your wallet address in the list. Good luck!',
              color: 'green',
              icon: <CheckIcon />
            })
          })
        }
      )
    } else {
      showNotification({
        title: 'Error',
        message: 'Please fill in all information.',
        color: 'red',
        icon: <Cross1Icon />
      })
    }
  }

  return (
    <>
      <Modal radius="md" centered withCloseButton={false} opened={opened} onClose={() => setOpened(false)} size="xl">
        <TypographyStylesProvider>
          <Stack>
            <Title order={1}>AirDrop</Title>
            <Text size="lg" style={{ color: '#3399ff' }}>
              Open: June 4th 00:00 GMT - June 6th 00:00 GMT
            </Text>
            <Text size="lg" style={{ color: '#3399ff' }}>
              Draw time: June 7th 12:00 GMT
            </Text>
            <Text>
              I will airdrop <b>50</b> editions (10 edition / #00-#04 works) winners of the lucky draw. To participate in the draw - please send me your{' '}
              <span style={{ color: '#3399ff' }}>Tezos</span> wallet address.
            </Text>
            <Input icon={<TezosIcon />} placeholder="your tezos address" size="lg" onChange={(e) => setAddress(e.target.value)} invalid={errorAd} />
            <Input icon={<TwitterLogoIcon />} placeholder="your name/twitter account" size="lg" onChange={(e) => setName(e.target.value)} invalid={errorName} />
            <Button variant="default" size="lg" onClick={createRecord}>
              Join lucky draw
            </Button>
            <Text size="md" style={{ color: '#3399ff' }}>
              * Duplicate addresses will be deleted
            </Text>
          </Stack>
        </TypographyStylesProvider>
        <Container className="controls">
          <Center className="btn">
            <Cross1Icon
              className="svg-icon"
              onClick={() => {
                setOpened(false)
              }}
            />
          </Center>
        </Container>
      </Modal>

      <Group position="center">
        <Button onClick={() => setOpened(true)}>Open Modal</Button>
      </Group>
    </>
  )
}

export default ModalDrop
