import {useRef, useState} from 'react'
import { useFrame } from '@react-three/fiber'
import { Vector3 } from 'three'
import { Box } from '@react-three/drei'

function FlashLight(props) {

  const { camera, isMobile = false, debug = true } = props
  const [distance, setDistance] = useState(isMobile ? 150 : 80)

  const flashLight = useRef()
  const debTarget = useRef()
  const lightParent = useRef()
  const genLight = useRef()
  let time = 0

  useFrame(async (state, delta) => {
    if (camera.current != undefined) {
      time += delta * 2

      // get direction of the camera
      let vector = new Vector3(0, 0, -1)
      vector.applyQuaternion(camera.current.quaternion).multiplyScalar(5)

      flashLight.current.position.x = camera.current.position.x - vector.x / 2
      flashLight.current.position.y = camera.current.position.y - vector.y / 2
      flashLight.current.position.z = camera.current.position.z - vector.z / 2
      flashLight.current.target.position.x = camera.current.position.x + vector.x
      flashLight.current.target.position.y = camera.current.position.y + vector.y
      flashLight.current.target.position.z = camera.current.position.z + vector.z
      genLight.current.position.x = flashLight.current.position.x
      genLight.current.position.y = flashLight.current.position.y
      genLight.current.position.z = flashLight.current.position.z
      flashLight.current.target.updateMatrixWorld()

      if (debug) {
        debTarget.current.position.x = flashLight.current.target.position.x
        debTarget.current.position.y = flashLight.current.target.position.y
        debTarget.current.position.z = flashLight.current.target.position.z
      }
    }
  })

  return (
    <group ref={lightParent}>
      {debug && <Box ref={debTarget} scale={[0.1, 0.1, 0.1]} />}
      <spotLight
        distance={distance}
        decay={3}
        // color={[1,0,0]}
        angle={isMobile ? .3 : .4}
        penumbra={1}
        intensity={1}
        castShadow
        shadow-mapSize={[1024, 1024]}
        ref={flashLight}
      />
      <pointLight
          intensity={.5}
          color={[0,0,.1]}
          distance={distance * 2}
          ref={genLight} />
    </group>
  )
}

export default FlashLight
