import {randomEuler, randomSpeed, randomVector} from "../lib/helpers";
import {Box} from "@react-three/drei";
import ObjectWrapperPassive from "./ObjectWrapperPassive";

const light = Array.from({ length: 5 }, (r = 150) => ({
    random: Math.random(.5),
    color: [Math.random(), Math.random(), Math.random()],
    position: randomVector(r),
    speed: randomSpeed(0.1)
}))

function TravelingLight() {

  return (
    <>
        {light.map((obj_prop, i) => (
            <ObjectWrapperPassive key={i} position={obj_prop.position} >
                <pointLight intensity={obj_prop.random} color={obj_prop.color} distance={60} />
                {/*<Box>*/}
                {/*    <meshStandardMaterial color={obj_prop.color} />*/}
                {/*</Box>*/}
            </ObjectWrapperPassive>

        ))}
      {/*<pointLight intensity={100} color={[1, 0, 0]} distance={100}  />*/}
    </>
  )
}

export default TravelingLight
