import {
  Modal,
  Button,
  Group,
  Title,
  Text,
  List,
  Center,
  Container,
  TypographyStylesProvider,
  Blockquote,
  Image
} from '@mantine/core'
import { Cross1Icon, DiscordLogoIcon, Link2Icon, PaperPlaneIcon, TwitterLogoIcon } from '@radix-ui/react-icons'

function ModalPerson({ opened, setOpened }) {
  return (
    <>
      <Modal radius="md" centered withCloseButton={false} opened={opened} onClose={() => setOpened(false)} size="xl">
        <Text>
          <br />
          <br />
        </Text>
        <TypographyStylesProvider>
          <Title order={1}>
            Vasily Betin : <span>artist intro</span>
          </Title>
          <Blockquote>Symbiosis with entropy and uncertainty to create.</Blockquote>
          <Center>
            <Image
                radius="md"
                width={300}
                src="/assets/vasily.png"
                alt="Vasily Betin"
                shadows="lg"
                p={12}
            />
          </Center>

          <Text size="lg">
            I am an entropy artist, New Media director of "Sokaris studio," educator, and Founder of LiaisonDAO (you will hear more about it soon). My main area
            of interest is self-organized systems, such as the environment, society, Technium, our body, or any complex object. So, I can say everything.
          </Text>
          <br />
          <Text size="lg">
            My story is relatively short but with an extensive scope of experience. With a background in mathematics and computer science, a master's degree in
            cinema studies. Full-time work as a photographer, developer, designer, and new media artist.
          </Text>
        </TypographyStylesProvider>
        <Container className="controls">
          <Center className="btn">
            <a href="https://Vasily.onl" target="_blank">
              <Link2Icon className="svg-icon" />
            </a>
          </Center>
          <Center className="btn">
            <a href="https://twitter.com/Vasily_onl" target="_blank">
              <TwitterLogoIcon className="svg-icon" />
            </a>
          </Center>
          <Center className="btn">
            <Cross1Icon
              className="svg-icon"
              onClick={() => {
                setOpened(false)
              }}
            />
          </Center>
        </Container>
      </Modal>

      <Group position="center">
        <Button onClick={() => setOpened(true)}>Open Modal</Button>
      </Group>
    </>
  )
}

export default ModalPerson
