import {
  Modal,
  Button,
  Group,
  Title,
  Text,
  List,
  Center,
  Container,
  TypographyStylesProvider,
  Image
} from '@mantine/core'
import { Cross1Icon, DiscordLogoIcon, Link2Icon, PaperPlaneIcon, TwitterLogoIcon } from '@radix-ui/react-icons'

import images from '../../data/images.json'

function ModalCatalogue({ opened, setOpened }) {
  return (
    <>
      <Modal radius="md" centered withCloseButton={false} opened={opened} onClose={() => setOpened(false)} size="xl">
        <Text>
          <br />
        </Text>
        <TypographyStylesProvider>
          <Title order={1}>POLAROID SERIES: catalog</Title>
          <Text>
            From June 11, I will add five more works to the project (virtual experience and objkt.org) weekly.
          </Text>
          {images.map((image, i) => (
            <Container key={i}>
              <Title order={3}>{image.title}</Title>
              <Image
                  radius="md"
                  width={600}
                  src={image.src}
                  alt={image.title}
                  shadows="lg"
              />
              <Text className="obj-desc">{image.desc}</Text>
              {image.link &&
                  <Text className="obj-link">
                    <a href={image.link} target="_blank" style={{color: '#4dabf7'}}>
                      Link to the work
                    </a>
                  </Text>
              }
            </Container>
          ))}
        </TypographyStylesProvider>
        <Container className="controls">
          <Center className="btn">
            <a href="https://objkt.com/collection/KT1MQdkMhmbnU3nwqkdhJsup7JNE2HA7ih7J" target="_blank">
              <Link2Icon className="svg-icon" />
            </a>
          </Center>
          <Center className="btn">
            <Cross1Icon
              className="svg-icon"
              onClick={() => {
                setOpened(false)
              }}
            />
          </Center>
        </Container>
      </Modal>

      <Group position="center">
        <Button onClick={() => setOpened(true)}>Open Modal</Button>
      </Group>
    </>
  )
}

export default ModalCatalogue
