import ModalInfo from "./Modal/ModalInfo";
import React, {useEffect, useRef, useState} from "react";
import {Vector3} from "three";
import Perlin, {checkAngleToObjectFromCameraView, checkDistanceToCamera} from "../lib/helpers";
import {useFrame} from "@react-three/fiber";

const max_speed = .002

function ObjectWrapper({children, speed, camera, actAngle = Math.PI, actDistance = 10, obj, ...props}) {

    const group = useRef()

    const [info, setInfo] = useState(false)
    const [active, setActive] = useState(false)

    const objPos = props.position
    const [distance, setDistance] = useState(0)
    const [ready, setReady] = useState(false)
    const [angle, setAngle] = useState(Math.PI)
    const [pos, setPos] = useState(new Vector3(objPos[0], objPos[1], objPos[2]))
    const [speedMul, setSpeedMul] = useState(1)

    const perlin = new Perlin()

    useEffect(() => {
      if (camera.current != undefined) {
        setReady(true)
      }
    }, [camera])

    useFrame(async (state, delta) => {
      if (ready) {
        checkDistanceToCamera(camera.current.position, group.current.position, setDistance)
        checkAngleToObjectFromCameraView(camera.current, pos, setAngle)

        // movement and rotation animation
        if (distance < actDistance * 2) {
          if (speedMul > 0.05) {
            setSpeedMul(speedMul * 0.995)
          }
        } else {
          if (speedMul < 1) {
            setSpeedMul(speedMul * 1.03)
          }
        }
        group.current.rotation.x += speed[0] * speedMul * (1 - info)
        group.current.rotation.y += speed[1] * speedMul * (1 - info)
        group.current.rotation.z += speed[2] * speedMul * (1 - info)

        group.current.position.x += perlin.noise(group.current.position.x, group.current.position.y) * max_speed * speedMul * (1 - info)
        group.current.position.y += perlin.noise(group.current.position.y, group.current.position.z) * max_speed * speedMul * (1 - info)
        group.current.position.z += perlin.noise(group.current.position.z, group.current.position.x) * max_speed * speedMul * (1 - info)

        // development
        if (distance < actDistance) {
          setActive(true)
        } else {
          setActive(false)
        }
      }
    })

    function clickHandler() {
        // console.log(distance)
        if(distance < actDistance * 1.5){
            setInfo(!info)
        }
    }

    return (
        <group
            {...props}
            onClick={clickHandler}
            active={active}
            ref={group}
        >
            {children}
            <ModalInfo
                title={obj?.title}
                desc={obj?.desc}
                link={obj?.link}
                image={obj?.img}
                open={info}
                setOpen={setInfo}
                distance={actDistance}
            />
        </group>
    )
}

export default ObjectWrapper