import Polaroid from '../Objects/Polaroid'
import { Bounds, useBounds } from '@react-three/drei'

import images from '../data/images_test.json'
import {randomEuler, randomSpeed, randomVector} from "../lib/helpers";
import {useFrame} from "@react-three/fiber";


const randomData = Array.from({ length: images.length }, (r = 75) => ({
  random: Math.random(),
  position: randomVector(r),
  rotation: randomEuler(),
  speed: randomSpeed(0.003)
}))

function Polaroids({ isMobile = false, camera }) {

  useFrame(async (state, delta) => {
    camera.current.closestDistance = 200
  })

  return (
    <>
      {images.map((image, i) => (
        <Polaroid
          key={i}
          position={randomData[i].position}
          rotation={randomData[i].rotation}
          speed={randomData[i].speed}
          camera={camera}
          obj={image}
          devDistance={10}
          devAngle={Math.PI / 2}
          // scale={[2,2,2]}
        />
      ))}
    </>
  )
}

export default Polaroids
