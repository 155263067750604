import {Base, Depth, LayerMaterial} from "lamina";
import * as THREE from "three";


function EnvSphere({bgColor}) {

    return (
        <mesh scale={1000}>
            <sphereGeometry args={[1, 64, 64]} />
            <LayerMaterial side={THREE.BackSide}>
                <Base color={bgColor} alpha={1} mode="normal" />
                {/*<Depth colorA="blue" colorB="black" alpha={0.5} mode="normal" near={0} far={50} origin={[100, 100, 100]} />*/}
            </LayerMaterial>
        </mesh>
    )
}

export default EnvSphere