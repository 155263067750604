

function TezosIcon(){

    return(
        <svg width="32" height="32" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M43.2021 38.6C42.9021 32.9 35.3021 30.3 34.6021 30C34.6021 30 34.6021 30 34.6021 29.9L42.4021 22.4V21.6C42.4021 21.5 42.3021 21.4 42.2021 21.4H27.9021V16.9V15.5L22.6021 16.6V17.4H22.9021C22.9021 17.4 24.2021 17.4 24.2021 18.6V21.4H20.1021C20.0021 21.4 20.0021 21.5 20.0021 21.5V23.3H24.3021V27.4V32.9C24.3021 35.9 26.3021 38 29.9021 37.7C30.7021 37.6 31.3021 37.4 31.9021 37C32.2021 36.8 32.3021 36.6 32.3021 36.3V35.4C31.1021 36.1 30.2021 36.1 30.2021 36.1C28.0021 36.1 28.0021 33.4 28.0021 33.4V23.5H38.3021L30.9021 30.6C30.9021 31.5 30.9021 32.3 30.9021 32.3C37.7021 33.4 39.6021 37.6 39.6021 38.8C39.6021 38.8 40.3021 44.8 34.1021 45.2C34.1021 45.2 30.0021 45.4 29.3021 43.8C29.3021 43.7 29.3021 43.7 29.4021 43.7C30.1021 43.4 30.5021 42.8 30.5021 42.1C30.5021 40.9 29.8021 40 28.2021 40C26.9021 40 25.9021 40.9 25.9021 42.1C25.9021 42.1 25.3021 47.1 34.1021 47C43.9021 46.6 43.2021 38.6 43.2021 38.6Z" fill="gray"/>
        </svg>
    )

}

export default TezosIcon