import { Html } from '@react-three/drei'
import React from 'react'
import {Container, Text, Title} from "@mantine/core";

function ModalInfo({ open, setOpen, title, desc, image, link, distance }) {
  return open ? (
    <Html distanceFactor={distance}>
      <Container className="content">
        <Title order={3} >{title}</Title>
        <div
          className="close-icon"
          onClick={() => {
            setOpen(false)
          }}
          style={{cursor: "pointer"}}
        >
          x
        </div>
        {image && (
          <div className="obj-img">
            <image src={image} alt={title} />
          </div>
        )}
        {desc && <Text className="obj-desc">{desc}</Text>}
        {link && (
          <Text className="obj-link">
            <a href={link} target="_blank" style={{color: '#4dabf7'}}>
              Link to the work
            </a>
          </Text>
        )}
      </Container>
    </Html>
  ) : (
    <></>
  )
}

export default ModalInfo
