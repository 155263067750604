import ReactDOM from 'react-dom'
import './styles.css'
import App from './App'
import Intro from './Intro'

import { MantineProvider} from '@mantine/core'
import { NotificationsProvider } from '@mantine/notifications';

ReactDOM.render(
  <MantineProvider withGlobalStyles withNormalizeCSS theme={{ colorScheme: 'dark' }}>
    <NotificationsProvider  autoClose={4000}>
      <Intro>
        <App />
      </Intro>
    </NotificationsProvider>
  </MantineProvider>,
  document.getElementById('root')
)
