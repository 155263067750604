/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import ObjectWrapper from '../Components/ObjectWrapper'

const obj = {
  title: 'Vase',
  desc: 'Vases are interesting object. Different shapes and glass properties allow to create interesting patterns of the light.'
}

export default function Bottle1({distance, ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/assets/objects/bottle_1.glb')

  const materialProps = {
    thickness: 2,
    roughness: 0.3,
    clearcoat: 1.0,
    clearcoatRoughness: 0.1,
    transmission: 0.75,
    ior: 2.5,
    envMapIntensity: 3,
    color: '#ff2222',
    attenuationTint: '#000000',
    attenuationDistance: 0
  }

  return (
    <ObjectWrapper {...props} dispose={null} obj={obj} actDistance={distance}>
      <group rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
        <group position={[0.01, -0.04, 1.77]}>
          <mesh geometry={nodes.skull_bottle001.geometry}>
            <meshPhysicalMaterial {...materialProps} />
          </mesh>
          <mesh geometry={nodes.skull_bottle003.geometry} material={materials['Ash Wood Satin #1']} />
          <mesh geometry={nodes.skull_bottle004.geometry} material={materials['Paint Matte Black']} />
        </group>
      </group>
    </ObjectWrapper>
  )
}

useGLTF.preload('/assets/objects/bottle_1.glb')
