/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import ObjectWrapper from '../Components/ObjectWrapper'

const obj = {
  title: 'Plate',
  desc: 'Common object to mix chemistry for film development.'
}

export default function Plate({ distance, ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/assets/objects/plate.glb')
  return (
    <ObjectWrapper {...props} dispose={null} obj={obj} actDistance={distance}>
      <mesh geometry={nodes.Circle.geometry} material={materials['Material.006']} position={[-0.01, 0, 0.01]} scale={-7.93} />
    </ObjectWrapper>
  )
}

useGLTF.preload('/assets/objects/plate.glb')
