import * as THREE from 'three'
import { useRef, useEffect, useState } from 'react'
import { Canvas } from '@react-three/fiber'
import { OrbitControls, PerspectiveCamera, Environment, Bounds, UseBounds, useBounds } from '@react-three/drei'

import FlashLight from './Components/FlashLight'
import Polaroids from './Components/Polaroids'
import EnvSphere from './Components/EnvSphere'
import Control from './Components/Control'
import Objects from './Components/Objects'
import TravelingLight from './Components/TravelingLight'
import Menu from './Components/UI/Menu'
import { Bloom, ChromaticAberration, DepthOfField, EffectComposer, Vignette } from '@react-three/postprocessing'
import Sound from "./Objects/Sound";
import IntroStory from "./Components/UI/IntroStory";
import {YMInitializer} from "react-yandex-metrika";
import Lost from "./Components/UI/Lost";
import {CheckIcon} from "@radix-ui/react-icons";
import {Notification} from "@mantine/core";

const debug = true

export default function App({ ready, drop }) {
  const camera = useRef()
  const topGroup = useRef()

  console.log(drop)

  // define if it opens on mobile phone or not
  const [width, setWidth] = useState(window.innerWidth)
  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])
  const isMobile = width <= 768

  const [control, setControl] = useState(undefined)
  const [clipBounds, setClipBounds] = useState(isMobile ? 0.7 : 1)
  const [boundsScale, setBoundsScale] = useState(1.2)
  const [fogDistance, setFogDistance] = useState(isMobile ? 100 : 60)
  const [fogColor, setFogColor] = useState('#000001')
  const [bgColor, setBgColor] = useState('#000007')
  const [ambLight, setAmbLight] = useState(0)
  const [sound, setSound] = useState(false)
  const [volume, setVolume] = useState(.15)
  const [openned, setOpenned] = useState(true)
  const [lost, setLost] = useState(false)

  const [envSetting, setEnvSettings] = useState(0)

  useEffect(()=>{
    setSound(ready)
  }, [ready])

  useEffect(() => {
    switch (envSetting) {
      case 0:
        setFogColor('#000001')
        setBgColor('#000000')
        setAmbLight(0)
        setFogDistance(isMobile ? 80 : 60)
        break
      case 1:
        setFogColor('#000005')
        setBgColor('#010103')
        setAmbLight(.1)
        setFogDistance(isMobile ? 120 : 75)
        break
      case 2:
        setFogColor('#010107')
        setBgColor('#111122')
        setAmbLight(.3)
        setFogDistance(isMobile ? 150 : 100)
        break
    }
  }, [envSetting])

  return (
    <>
      <Canvas dpr={[1, 2]} shadows>
        <Environment files="assets/satara_night_no_lamps_1k.hdr" />
        <fog attach="fog" args={[fogColor, 16, fogDistance]} />
        <ambientLight intensity={ambLight} />
        <Bounds margin={clipBounds * boundsScale} damping={.6} observe>
          <Lost lost={lost} setLost={setLost} camera={camera} />
          <SelectToZoom setBounds={setBoundsScale}>
            <Polaroids camera={camera} isMobile={isMobile} />
            <Objects camera={camera} isMobile={isMobile} />
          </SelectToZoom>
        </Bounds>
        <Sound url="assets/music/ROZKOL - Clot I_01.mp3" rollof={100} playback={sound} debug={false} volume={volume} />
        <TravelingLight />
        <group ref={topGroup}>
          <FlashLight camera={camera} parent={topGroup} isMobile={isMobile} debug={false} />
        </group>
        <EnvSphere bgColor={bgColor}/>
        <Control setParControl={setControl} />
        <PerspectiveCamera makeDefault fov={50} position={[0, 0, 80]} ref={camera} closestDistance={100} closestObject={null} ></PerspectiveCamera>
        <IntroStory camera={camera} isMobile={isMobile} ready={!openned && ready} />
      </Canvas>
      <Menu ready={ready} envSetting={envSetting} setEnvSettings={setEnvSettings} volume={volume} setVolume={setVolume} setWindow={setOpenned} setLost={setLost} isMobile={isMobile} drop={drop} />
      <YMInitializer accounts={[88896209]} />
    </>
  )
}

// This component wraps children in a group with a click handler
// Clicking any object will refresh and fit bounds
function SelectToZoom({ children }) {
  const api = useBounds()
  return <group onClick={(e) => (e.stopPropagation(), !e.object.parent?.active && e.delta <= 2 && api.refresh(e.object).fit())}>{children}</group>
}
