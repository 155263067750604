/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import ObjectWrapper from '../Components/ObjectWrapper'

const obj = {
  title: 'Plastic bottles',
  desc: 'Plastic another interesting material after glass that allow to get more soft and abstract pattern of the light'
}

export default function Bottle3({distance, ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/assets/objects/bottle_3.glb')

  nodes.Circle.material.color.r = 0.01
  nodes.Circle.material.color.b = 0.2
  nodes.Circle.material.color.g = 0.05
  nodes.Circle.material.metalness = 0.5
  nodes.Circle.material.opacity = 0.9
  nodes.Circle.material.transparent = true
  nodes.Circle.material.EmissiveIntensity = 0

  return (
    <ObjectWrapper {...props} dispose={null} obj={obj} actDistance={distance}>
      <mesh geometry={nodes.Circle.geometry} material={materials['Material.002']} position={[0.77, 0.14, -0.07]} rotation={[Math.PI / 2, 0, 0]} />
    </ObjectWrapper>
  )
}

useGLTF.preload('/assets/objects/bottle_3.glb')
