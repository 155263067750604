import { Modal, Button, Group, Title, Text, List, Center, Container, TypographyStylesProvider } from '@mantine/core'
import {
  ClockIcon,
  CropIcon,
  Cross1Icon,
  ExclamationTriangleIcon,
  ReaderIcon,
  SpeakerLoudIcon,
  SpeakerOffIcon,
  SymbolIcon,
  ZoomInIcon
} from '@radix-ui/react-icons'

function ModalControl({ opened, setOpened, envSetting, setEnvSettings, volume, setVolume }) {
  function setEnv(env) {
    setEnvSettings(env)
  }

  function setSound() {
    if (volume > 0) {
      setVolume(0)
    } else {
      setVolume(0.15)
    }
  }

  return (
    <>
      <Modal radius="md" centered withCloseButton={false} opened={opened} onClose={() => setOpened(false)} size="xl">
        <TypographyStylesProvider>
          <Title order={1}>CONTROL</Title>
          <List spacing="md" size="md" center icon={<ExclamationTriangleIcon />} style={{ paddingLeft: 0 }}>
            <List.Item icon={<SymbolIcon />}>Press and move mouse (touch) to spin around the object.</List.Item>
            <List.Item icon={<ZoomInIcon />}>Click on the remote object to fly it.</List.Item>
            <List.Item icon={<ClockIcon />}>When you close to the Polaroid it will start develop image.</List.Item>
            <List.Item icon={<ReaderIcon />}>
              While near the object click it to show information about object. On the polaroid information window you can find link to the work at the
              marketplace.
            </List.Item>
            <List.Item icon={<CropIcon />}>If you lost in the dark click <CropIcon /> to find closest Polaroid to you.</List.Item>
          </List>
          <Title order={3}>SETTINGS</Title>
          <Text>You can adjust brightness of the scene if it hard to see on your device</Text>
          <br />
          <Group>
            <Button
              disabled={envSetting === 0 ? true : false}
              variant="default"
              radius="md"
              size="md"
              onClick={() => {
                setEnv(0)
              }}>
              Default
            </Button>
            <Button
              disabled={envSetting === 1 ? true : false}
              variant="default"
              radius="md"
              size="md"
              onClick={() => {
                setEnv(1)
              }}>
              Light
            </Button>
            <Button
              disabled={envSetting === 2 ? true : false}
              variant="default"
              radius="md"
              size="md"
              onClick={() => {
                setEnv(2)
              }}>
              Bright
            </Button>
          </Group>
        </TypographyStylesProvider>
        <Container className="controls">
          <Center className="btn">
            {volume ? <SpeakerLoudIcon className="svg-icon" onClick={setSound} /> : <SpeakerOffIcon className="svg-icon" onClick={setSound} />}
          </Center>
          <Center className="btn">
            <Cross1Icon
              className="svg-icon"
              onClick={() => {
                setOpened(false)
              }}
            />
          </Center>
        </Container>
      </Modal>

      <Group position="center">
        <Button onClick={() => setOpened(true)}>Open Modal</Button>
      </Group>
    </>
  )
}

export default ModalControl
