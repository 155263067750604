/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/
import React, { useEffect, useRef, useState } from 'react'
import {Html, useGLTF} from '@react-three/drei'
import { applyProps, useFrame, useLoader } from '@react-three/fiber'
import { TextureLoader, Vector3 } from 'three'
import Perlin, {checkAngleToObjectFromCameraView, checkDistanceToCamera} from "../lib/helpers";
import ModalInfo from "../Components/Modal/ModalInfo";

const max_speed = .002

export default function Polaroid({ obj, speed, camera, devAngle = Math.PI, devDistance = 5, ...props }) {

  const group = useRef()
  const { nodes, materials } = useGLTF('/assets/objects/polaroid.glb')
  const colorMap = useLoader(TextureLoader, obj.src)

  const objPos = props.position
  const [distance, setDistance] = useState(0)
  const [ready, setReady] = useState(false)
  const [angle, setAngle] = useState(Math.PI)
  const [pos, setPos] = useState(new Vector3(objPos[0], objPos[1], objPos[2]))
  const [speedMul, setSpeedMul] = useState(1)

  const [info, setInfo] = useState(false)

  const [dev, setDev] = useState(false)
  const [devSpeed, setDevSpeed] = useState(0.1)
  const [opacity, setOpacity] = useState(0)

  materials.body.envMapIntensity = 1
  materials.image.envMapIntensity = 1
  materials.backfim.envMapIntensity = 2

  const perlin = new Perlin()

  useEffect(() => {
    if (camera.current != undefined) {
      setReady(true)
    }
  }, [camera])

  useFrame(async (state, delta) => {
    if (ready) {
      checkDistanceToCamera(camera.current.position, group.current.position, setDistance)
      checkAngleToObjectFromCameraView(camera.current, pos, setAngle)

      if(camera.current.closestDistance > distance) {
        camera.current.closestDistance = distance
        camera.current.closestObject = group
      }

      // movement and rotation animation
      if (distance < devDistance * 2) {
        if (speedMul > 0.05) {
          setSpeedMul(speedMul * 0.995)
        }
      } else {
        if (speedMul < 1) {
          setSpeedMul(speedMul * 1.03)
        }
      }
      group.current.rotation.x += speed[0] * speedMul * (1-info)
      group.current.rotation.y += speed[1] * speedMul * (1-info)
      group.current.rotation.z += speed[2] * speedMul * (1-info)

      group.current.position.x += perlin.noise(group.current.position.x, group.current.position.y) * max_speed * speedMul * (1-info)
      group.current.position.y += perlin.noise(group.current.position.y, group.current.position.z) * max_speed * speedMul * (1-info)
      group.current.position.z += perlin.noise(group.current.position.z, group.current.position.x) * max_speed * speedMul * (1-info)

      // development
      if (distance < devDistance) {
        setDev(true)
      } else {
        setDev(false)
      }
      if (dev) {
        if (opacity < 1) {
          setOpacity(opacity + delta * devSpeed)
        }
      }
    }
  })

  function clickHandler() {
    if(distance < devDistance * 1.5){
      setInfo(!info)
    }
  }

  return (
    <group
      ref={group}
      {...props}
      dispose={null}
      onClick={clickHandler}
      active={dev}
    >
      <mesh geometry={nodes.body.geometry} material={materials.body} position={[-0.16, 0, 0.48]} scale={[1.14, 1, 1.85]} />
      <mesh geometry={nodes.image.geometry} material={materials.image} position={[-0.16, 0, 0.48]} scale={[1.14, 1, 1.85]}></mesh>
      <mesh geometry={nodes.image.geometry} material={materials.image} position={[-0.16, 0.01, 0.48]} scale={[1.14, 1, 1.85]}>
        <meshPhysicalMaterial map={colorMap} clearcoat={1} clearcoatRoughness={0.2} transparent={true} opacity={opacity} />
      </mesh>
      <mesh geometry={nodes.backfilm.geometry} material={materials.backfim} position={[-0.16, 0, 0.48]} scale={[1.14, 1, 1.85]} />
      <ModalInfo
          title={obj.title}
          link={obj.link}
          desc={obj.desc}
          open={info}
          setOpen={setInfo}
          distance={8}
      />
    </group>
  )
}

useGLTF.preload('/assets/objects/polaroid.glb')
