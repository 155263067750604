/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import ObjectWrapper from '../Components/ObjectWrapper'

const obj = {
  title: 'Glass Bottle',
  desc: 'Bottles from different alcohol or beverage are good object to reflect and shape the light as the do have different shapes, patterns and glass quality.' +
      ' Bottles also the storage for chemistry and liquids was used for the development of film.'
}

export default function Bottle4({distance, ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/assets/objects/bottle_4.glb')

  const materialProps = {
    thickness: 1.5,
    roughness: 0.2,
    clearcoat: 1.0,
    clearcoatRoughness: 0.1,
    transmission: 0.99,
    ior: 1.9,
    envMapIntensity: 3,
    color: '#ffaaaa',
    attenuationTint: '#000000',
    attenuationDistance: 0
  }

  return (
    <ObjectWrapper {...props} dispose={null} obj={obj} actDistance={distance}>
      <mesh geometry={nodes.Bottle_001.geometry} rotation={[Math.PI / 2, 0, 0]}>
        <meshPhysicalMaterial {...materialProps} />
      </mesh>
    </ObjectWrapper>
  )
}

useGLTF.preload('/assets/objects/bottle_4.glb')
