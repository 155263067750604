/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import ObjectWrapper from '../Components/ObjectWrapper'

const obj = {
  title: "Glass",
  desc: "Glasses like the bottles very accessible and useful object for light deformation."
}

export default function WineGlass({ distance, ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/assets/objects/wine_glass.glb')

  const materialProps = {
    thickness: 1.5,
    roughness: 0.2,
    clearcoat: 1.0,
    clearcoatRoughness: 0.2,
    transmission: 0.97,
    ior: 1.3,
    envMapIntensity: 3,
    color: '#aaaaff',
    attenuationTint: '#000000',
    attenuationDistance: 0
  }

  return (
    <ObjectWrapper {...props} dispose={null} scale={[50, 50, 50]} obj={obj} actDistance={distance}>
      <mesh geometry={nodes.sarapbitmishalipCylinder1.geometry} position={[0, 0, 0.04]} rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
        <meshPhysicalMaterial {...materialProps} />
      </mesh>
    </ObjectWrapper>
  )
}

useGLTF.preload('/assets/objects/wine_glass.glb')
