/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import ObjectWrapper from '../Components/ObjectWrapper'

const obj = {
  title: 'Paper clip',
  desc: 'Simple object to hold the film or used as paint brush for the chemistry.'
}

export default function PaperClip1({ distance, ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/assets/objects/paper_clip_1.glb')
  return (
    <ObjectWrapper {...props} dispose={null} obj={obj} actDistance={distance}>
      <group>
        <mesh geometry={nodes.Metal_Paper_Clip_Model.geometry} material={materials['Material #2']} position={[-0.05, -0.08, 0.54]} />
      </group>
    </ObjectWrapper>
  )
}

useGLTF.preload('/assets/objects/paper_clip_1.glb')
