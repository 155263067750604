import { randomEuler, randomSpeed, randomVector } from '../lib/helpers'
import { useState } from 'react'

import WineGlass from '../Objects/Wine_glass'
import SteelSpatula from '../Objects/SteelSpatula'
import Bottle1 from '../Objects/Bottle_1'
import Bottle3 from '../Objects/Bottle_3'
import Bottle4 from '../Objects/Bottle_4'
import BrokenGlass1 from '../Objects/Broken_glass_1'
import BrokenGlass2 from '../Objects/Broken_glass_2'
import FlashLight1 from '../Objects/Flash_ligh_1'
import FlashLight2 from '../Objects/Flash_light_2'
import Prism1 from '../Objects/Prism_1'
import Prism2 from '../Objects/Prism_2'
import Laser from '../Objects/Laser'
import PaintBrush from '../Objects/Paint_brush'
import Toothbrush from '../Objects/Toothbrush'
import PaperClip1 from '../Objects/Paper_clip_1'
import PaperClip2 from '../Objects/Paper_clip_2'
import Phone from '../Objects/Phone'
import Plate from '../Objects/Plate'

const space = 80

const wineGlasses = Array.from({ length: 1 }, (r = space) => ({
  random: Math.random(),
  position: randomVector(r),
  rotation: randomEuler(),
  speed: randomSpeed(0.002)
}))

const steelSpatula = Array.from({ length: 1 }, (r = space) => ({
  random: Math.random(),
  position: randomVector(r),
  rotation: randomEuler(),
  speed: randomSpeed(0.001)
}))

// TODO: for bottles randomize their color and transparency a bit
const bottles = Array.from({ length: 2 }, (r = space) => ({
  random: Math.random(),
  position: randomVector(r),
  rotation: randomEuler(),
  speed: randomSpeed(0.001)
}))

// TODO: scale randomize, transparency and roughness randomize
const brokenGlass = Array.from({ length: 2 }, (r = space * 1.3) => ({
  type: Math.random(),
  random: Math.random(),
  opacity: Math.random(),
  scale: Math.random() * 0.3 + 0.1,
  roughness: Math.random(),
  position: randomVector(r),
  rotation: randomEuler(),
  speed: randomSpeed(0.001)
}))

const prism = Array.from({ length: 2 }, (r = space) => ({
  type: Math.random(),
  random: Math.random(),
  opacity: Math.random(),
  scale: Math.random() * 0.1 + 0.05,
  roughness: Math.random(),
  position: randomVector(r),
  rotation: randomEuler(),
  speed: randomSpeed(0.001)
}))

const flashLight = Array.from({ length: 1 }, (r = space / 1.5) => ({
  random: Math.random(),
  position: randomVector(r),
  rotation: randomEuler(),
  speed: randomSpeed(0.003)
}))

const lasers = Array.from({ length: 1 }, (r = space / 2) => ({
  random: Math.random(),
  position: randomVector(r),
  rotation: randomEuler(),
  speed: randomSpeed(0.001)
}))

const paintBrush = Array.from({ length: 1 }, (r = space) => ({
  random: Math.random(),
  position: randomVector(r),
  rotation: randomEuler(),
  speed: randomSpeed(0.001)
}))

const toothbrush = Array.from({ length: 1 }, (r = space) => ({
  random: Math.random(),
  position: randomVector(r),
  rotation: randomEuler(),
  speed: randomSpeed(0.001)
}))

const paperClip = Array.from({ length: 2 }, (r = space) => ({
  random: Math.random(),
  position: randomVector(r),
  rotation: randomEuler(),
  speed: randomSpeed(0.001)
}))

const phone = Array.from({ length: 1 }, (r = space / 2) => ({
  random: Math.random(),
  position: randomVector(space),
  rotation: randomEuler(),
  speed: randomSpeed(0.002)
}))

const plates = Array.from({ length: 1 }, (r = space) => ({
  random: Math.random(),
  position: randomVector(r),
  rotation: randomEuler(),
  speed: randomSpeed(0.002)
}))

function Objects({ isMobile = false, ...props }) {

  return (
    <>
      <group key="glass-1">
        {wineGlasses.map((obj_prop, i) => (
          <WineGlass key={`glass-${i}`} distance={15} {...obj_prop} {...props} />
        ))}
      </group>
      <group key="bottles-2">
        {bottles.map(
          (obj_prop, i) =>
            obj_prop.random > 0.75 ? (
              <Bottle1 distance={10} key={`botle-${i}`} {...obj_prop} scale={[20, 20, 20]} {...props} />
            ) : obj_prop.random > 0.5 ? (
              <Bottle3 distance={15} key={`botle-${i}`} {...obj_prop} scale={[0.7, 0.7, 0.7]} {...props} />
            ) : (
              <Bottle4 distance={25} key={`botle-${i}`} {...obj_prop} scale={[0.5, 0.5, 0.5]} {...props} />
            )
          // <Bottle2 key={i} {...obj_prop} scale={[1000,1000,1000]} {...props} /> Doesnt works, need check
        )}
      </group>
      <group key="glass-3">
        {brokenGlass.map((obj_prop, i) =>
          obj_prop.type > 0.5 ? (
            <BrokenGlass1 distance={20} key={`glass-b-${i}`} {...obj_prop} scale={[obj_prop.scale * 0.3, obj_prop.scale * 0.3, obj_prop.scale * 0.3]} {...props} />
          ) : (
            <BrokenGlass2 distance={20} key={`glass-b-${i}`} {...obj_prop} scale={[obj_prop.scale, obj_prop.scale, obj_prop.scale]} {...props} />
          )
        )}
      </group>
      <group key="prism-4">
        {prism.map((obj_prop, i) =>
          obj_prop.type > 0.5 ? (
            <Prism1 distance={12} key={`prism-${i}`} {...obj_prop} scale={[obj_prop.scale, obj_prop.scale, obj_prop.scale]} {...props} />
          ) : (
            <Prism2 distance={12} key={`prism-${i}`} {...obj_prop} scale={[obj_prop.scale, obj_prop.scale, obj_prop.scale]} {...props} />
          )
        )}
      </group>
      <group key="flash-5">
        {flashLight.map((obj_prop, i) =>
          obj_prop.random > 0.5 ? (
            <FlashLight1 distance={20} key={`flash-${i}`} {...obj_prop} {...props} scale={[0.7, 0.7, 0.7]} />
          ) : (
            <FlashLight2 distance={20} key={`flash-${i}`} {...obj_prop} {...props} scale={[0.6, 0.6, 0.6]} />
          )
        )}
      </group>
      <group key="laser-6">
        {lasers.map((obj_prop, i) => (
          <Laser distance={20} key={`laser-${i}`} {...obj_prop} {...props} scale={[5, 5, 5]} />
        ))}
      </group>
      <group key="spatula-7">
        {steelSpatula.map((obj_prop, i) => (obj_prop.type > 0.3 ? <SteelSpatula key={`spatula-${i}`} distance={10} {...obj_prop} scale={[2, 2, 2]} {...props} /> : <></>))}
      </group>
      <group key="paint-brush-8">
        {paintBrush.map((obj_prop, i) =>
          obj_prop.random > 0.3 ? <PaintBrush distance={10} key={`paintbrush-${i}`} {...obj_prop} {...props} scale={[0.01, 0.01, 0.01]} /> : <></>
        )}
      </group>
      <group key="toothbrush-9">
        {toothbrush.map((obj_prop, i) => (obj_prop.type > 0.3 ? <Toothbrush distance={15} key={`toothbrush-${i}`} {...obj_prop} {...props} scale={[0.5, 0.5, 0.5]} /> : <></>))}
      </group>
      <group key="paperclip-10">
        {paperClip.map((obj_prop, i) =>
          obj_prop.random > 0.5 ? (
            <PaperClip1 distance={5} key={`paperclip-${i}`} {...obj_prop} {...props} scale={[1, 1, 1]} />
          ) : (
            <PaperClip2 distance={5} key={`paperclip-${i}`} {...obj_prop} {...props} scale={[0.2, 0.2, 0.2]} />
          )
        )}
      </group>
      <group key="phone-11">
        {phone.map((obj_prop, i) => (
          <Phone distance={20} key={`phone-${i}`} {...obj_prop} {...props} scale={[1, 1, 1]} />
        ))}
      </group>
      <group key="plate-12">
        {plates.map((obj_prop, i) => (
          <Plate distance={15} key={`plate-${i}`} {...obj_prop} {...props} scale={[3, 3, 3]} />
        ))}
      </group>
    </>
  )
}

export default Objects
