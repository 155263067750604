/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import ObjectWrapper from "../Components/ObjectWrapper";

const obj = {
  title: 'Paper clip',
  desc: 'Simple object to hold the film or used as paint brush for the chemistry.'
}

export default function PaperClip2({ distance, ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/assets/objects/paper_clip_2.glb')
  return (
    <ObjectWrapper {...props} dispose={null} obj={obj} actDistance={distance}>
      <group rotation={[Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes.sceneobj_1.geometry} material={materials.Black} />
        <mesh geometry={nodes.sceneobj_2.geometry} material={materials.Metal} />
      </group>
    </ObjectWrapper>
  )
}

useGLTF.preload('/assets/objects/paper_clip_2.glb')
