import { useEffect, useRef, useState } from 'react'
import { useLoader, useThree } from '@react-three/fiber'
import * as THREE from 'three'
import { Box } from '@react-three/drei'

function Sound({
  url,
  volume = 1,
  playback = true,
  model = null,
  cam,
  coneInner = 360,
  coneOuter = 360,
  rollof = 1,
  distanceModel = 'exponential',
  debug,
  parent = null,
  ...props
}) {
  const sound = useRef()
  const { camera } = useThree()
  const [listener] = useState(() => new THREE.AudioListener())
  const buffer = useLoader(THREE.AudioLoader, url)
  const [play, setPlay] = useState(playback)
  const [ready, setReady] = useState(false)

  useEffect(() => {
    sound.current.setBuffer(buffer)
    sound.current.setRefDistance(100)
    sound.current.panner.distanceModel = distanceModel
    sound.current.panner.rolloffFactor = rollof
    sound.current.panner.coneInnerAngle = coneInner
    sound.current.panner.coneOuterAngle = coneOuter
    sound.current.setLoop(true)
    sound.current.play()
    sound.current.gain.gain.value = volume
    camera.add(listener)
    // console.log('loaded - ', url)
    // console.log(sound)
    setReady(true)
    return () => camera.remove(listener)
  }, [])

  useEffect(() => {
    sound.current.gain.gain.value = volume
  }, [volume])

  useEffect(() => {
    if (playback) {
      setPlay(true)
      // sound.current.gain.gain.value = 1;
      sound.current.play()
      // console.log('started - ', url)
    } else {
      setPlay(false)
      // sound.current.gain.gain.value = 0;
      sound.current.stop()
      // console.log('stopped - ', url)
    }
  }, [playback])

  return debug ? (
    <Box onClick={clickHandler} {...props}>
      <positionalAudio autoplay={true} ref={sound} args={[listener]} />
    </Box>
  ) : (
    <positionalAudio autoplay={false} ref={sound} args={[listener]} {...props} />
  )
}

export default Sound
