import { Modal, Button, Group, Title, Center, Container, Text, Image as MImage } from '@mantine/core'
import { CheckIcon, Cross1Icon } from '@radix-ui/react-icons'
import { CopyToClipboard } from 'react-copy-to-clipboard/src'
import { showNotification } from '@mantine/notifications'

function ModalResult({ opened, setOpened, success, isMobile }) {

  function showCopied() {
    showNotification({
      title: 'Copied',
      message: 'Your personal link for share copied into clipboard',
      color: 'green',
      icon: <CheckIcon />
    })
  }

  function downloadImage(src) {
    const img = new Image();
    img.crossOrigin = 'anonymous';  // This tells the browser to request cross-origin access when trying to download the image data.
    // ref: https://developer.mozilla.org/en-US/docs/Web/HTML/CORS_enabled_image#Implementing_the_save_feature
    img.src = src;
    img.onload = () => {
      // create Canvas
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      // create a tag
      const a = document.createElement('a');
      a.download = 'Polaroids.png';
      a.href = canvas.toDataURL('image/png');
      a.click();
      showNotification({
        title: 'Saved',
        message: "'Polaroids.png' saved to your device",
        color: 'green',
        icon: <CheckIcon />
      })
    };
  }

  return (
    <>
      <Modal radius="md" centered withCloseButton={false} opened={opened} onClose={() => setOpened(false)} size="xl">
        <Title order={2}>You in!</Title>
        <br />
        <Text>
          Please share your personal link with your friends to increase your chance of winning!
        </Text>
        <CopyToClipboard text={`https://polaroids.vasily.onl/?link=${success}`} onCopy={showCopied} style={{ cursor: 'pointer' }}>
          <Text>
            <span style={{ color: '#3399ff' }}> https://polaroids.vasily.onl/?link={success}</span>
            .&nbsp; Click to copy the link into your clipboard.
          </Text>
        </CopyToClipboard>
        <Center>
          <MImage
              radius="md"
              width={isMobile ? 300 : 600}
              src="/assets/poster.jpg"
              alt="Vasily Betin"
              shadows="lg"
              p={12}
              onClick={()=>{
                downloadImage('/assets/posters/p1.jpg')
              }}
          />

        </Center>
        <Center>
          <Text onClick={()=>{
            downloadImage('/assets/poster.jpg')
          }}>
            Click on the poster to save it to your device.
          </Text>
        </Center>
        <br />
        <Text>
          Enjoy the virtual experience, and I wish you luck in the draw.
        </Text>

        <Container className="controls">
          <Center className="btn">
            <Cross1Icon
              className="svg-icon"
              onClick={() => {
                setOpened(false)
              }}
            />
          </Center>
        </Container>
      </Modal>

      <Group position="center">
        <Button onClick={() => setOpened(true)}>Open Modal</Button>
      </Group>
    </>
  )
}

export default ModalResult
