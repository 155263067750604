import { Modal, Button, Group, Title, Text, List, Center, Container, TypographyStylesProvider } from '@mantine/core'
import { Cross1Icon, DiscordLogoIcon, Link2Icon, PaperPlaneIcon, TwitterLogoIcon } from '@radix-ui/react-icons'

function ModalProject({ opened, setOpened }) {
  return (
    <>
      <Modal radius="md" centered withCloseButton={false} opened={opened} onClose={() => setOpened(false)} size="xl">
        <Text>
          <br />
          <br />
        </Text>
        <TypographyStylesProvider >
          <Title order={1}>POLAROID SERIES</Title>
          <Text size="lg">
            Our world is the genuinely generative masterpiece of billion forces in this universe. The Polaroid series is just a way to express and explore the
            analog way of creating generative works using light and different materials, embracing the uncertainty of random events and meditative processes.
          </Text>
          <br/>
          <Text size="lg">
            Generative artworks are reaching their peak popularity in recent years. Advancement of computational power, simplicity, accessibility of
            technologies, digitalization of life - many reasons bring generative art into popular widespread. The visual part became the goal of the works, not
            only the tool to visualize information. Tools and software allow us to experiment and simulate different systems and use visual language to
            visualize them.
          </Text>
          <br/>
          <Text size="lg">
            Digitalization of our life brings us unlimited virtual spaces where we almost don't have boundaries and limits to our imagination. Meanwhile, we are
            paying less and less attention to the analog world. I am fascinated with the complexity and complete unpredictability of our world. If we look
            around at the details of the world, we will see masterpieces of the generative nature of things. Scratches on the wall in composition with splashes
            of dirt and traces of the glue from someone advertising. This fascination leads me to document these masterpieces and desire to work with a more
            tangible medium rather than a virtual one.
          </Text>
          <br/>
          <Text size="lg">
            This series of works is one of many experiments I conducted in the past years. A big part of this work was produced during the first lockdown in
            Shanghai in 2020. I am working on this online exhibition and writing this text while being locked again at my home. Instax polaroids films were used
            as a canvas where my brushes are any object I can reach in my house, and my paint is nature of light and chemistry of various liquids and mater.
          </Text>
          <Title order={2}>Materials</Title>
          <Text size="lg">
            Instax polaroid film, light reflection materials (glass, broken glass, metal parts, prism, bottles, etc.), light sources (flash, laser, screen,
            etc.), chemistry (alcohol, water, disinfectants, toothpaste, marinade, etc.), environment (fire, freezer, hairdryer, etc.)
          </Text>
          <Title order={2}>Works release</Title>
          <Text size="lg">
            The series will consist of 50 works from different periods, some of them not even done. Every week I will release five pieces on the Tezos platform
            (you can buy a high-resolution digital NFT version on the climate-friendly platform to support my works).
            <List size="lg">
              <List.Item>The first five works will have multiple editions and a fixed price.</List.Item>
              <List.Item>The second release will have a fixed price and a single edition.</List.Item>
              <List.Item>
                Every consequent release's initial price will grow (2.5, 5, 10, 15, 20, 25, 50, 100tez).
              </List.Item>
            </List>
          </Text>
          <Text>
            Background music by <a href="https://rozkol.bandcamp.com/" target="_blank">ROZKOL</a> under <a href="https://creativecommons.org/licenses/by/4.0/" target="_blank">CC BY 4.0</a>
          </Text>
        </TypographyStylesProvider>
        <Container className="controls">
          <Center className="btn">
            <a href="https://objkt.com/collection/KT1MQdkMhmbnU3nwqkdhJsup7JNE2HA7ih7J" target="_blank">
              <Link2Icon className="svg-icon" />
            </a>
          </Center>
          <Center className="btn">
            <a href="https://discord.gg/Jce5ycXRJq" target="_blank">
              <DiscordLogoIcon
                className="svg-icon"
                onClick={() => {
                  setInfoOpened(!infoOpened)
                }}
              />
            </a>
          </Center>
          <Center className="btn">
            <a href="https://twitter.com/Vasily_onl" target="_blank">
              <TwitterLogoIcon className="svg-icon" />
            </a>
          </Center>
          <Center className="btn">
            <a href="https://t.me/FromNothing" target="_blank">
              <PaperPlaneIcon className="svg-icon" />
            </a>
          </Center>
          <Center className="btn">
            <Cross1Icon
              className="svg-icon"
              onClick={() => {
                setOpened(false)
              }}
            />
          </Center>
        </Container>
      </Modal>

      <Group position="center">
        <Button onClick={() => setOpened(true)}>Open Modal</Button>
      </Group>
    </>
  )
}

export default ModalProject
