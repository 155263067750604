/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import ObjectWrapper from '../Components/ObjectWrapper'

const obj = {
  title: 'Steel Spatula',
  desc: 'Another object that could be used as a brush for various chemical and liquids to create patterns on the molecular level.'
}

export default function SteelSpatula({ distance, ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/assets/objects/SteelSpatula.glb')

  materials.SilverH.envMapIntensity = 3
  return (
    <ObjectWrapper {...props} dispose={null} obj={obj} actDistance={distance}>
      <group active={true}>
        <mesh geometry={nodes.blt1.geometry} material={materials.SBolt1} position={[0, 0.12, -0.54]} rotation={[-1.53, 0, 0]} scale={0.01} />
        <mesh geometry={nodes.blt2.geometry} material={materials.SBolt1} position={[0, 0.02, -0.55]} rotation={[-1.53, 0, 0]} scale={0.01} />
      </group>
      <mesh geometry={nodes.WoodHand1.geometry} material={materials.Woodm1} position={[0, 0.04, -0.54]} rotation={[-1.53, 0, 0]} scale={0.01} />
      <mesh geometry={nodes.WoodHand2.geometry} material={materials.Woodm1} position={[0, 0.1, -0.54]} rotation={[-1.53, 0, 0]} scale={0.01} />
      <mesh geometry={nodes.SteelSpatula1.geometry} material={materials.SilverH} position={[0, 0.04, 0]} rotation={[-1.53, 0, 0]} scale={0.01} />
    </ObjectWrapper>
  )
}

useGLTF.preload('/assets/objects/SteelSpatula.glb')
