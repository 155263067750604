import { useFrame } from '@react-three/fiber'
import { useRef, useState } from 'react'
import { Vector3 } from 'three'
import { Container, Text, Title } from '@mantine/core'
import { Html } from '@react-three/drei'

function IntroStory({ ready, camera, isMobile = false }) {
  const group = useRef()
  const [curState, setCurState] = useState(0)
  const tm = [10, 8.0, 8.0, 8.0]
  const [leftTime, setLeftTime] = useState(1.0)

  // console.log(ready)

  function runNext(){
    // console.log('next')
    setLeftTime(tm[curState])
    setCurState(curState + 1)
  }

  useFrame(async (state, delta) => {
    if (ready) {
      // position of the text
      let vector = new Vector3(0, 0, -1)
      vector.applyQuaternion(camera.current.quaternion).multiplyScalar(5)
      group.current.position.x = camera.current.position.x + vector.x / 2
      group.current.position.y = camera.current.position.y + vector.y / 2
      group.current.position.z = camera.current.position.z + vector.z / 2
      group.current.lookAt(camera.current.position)

      if (curState < 4) {
        if (leftTime <= 0) {
          if (curState < 3) {
            setLeftTime(tm[curState])
            setCurState(curState + 1)
          } else {
            setCurState(curState + 1)
          }
        } else {
          setLeftTime(leftTime - delta)
        }
      }
    }
  })

  return (
    <group ref={group}>
      {curState > 0 && curState < 4 && (
        <Html>
          <Container className="introText">
            {curState === 1 && (
              <Title order={2}>
                Born in the dark
                <br />
                Childs of the chaos
                <br />
                With rays and the matter
                <br />
                Inherit from parents
                <br/>
                <a style={{fontSize: 18, color: "#777777", fontWeight: "normal"}} href="#" onClick={runNext}>Next</a>
              </Title>
            )}
            {curState === 2 && (
              <Title order={2}>
                Explore them himself
                <br />
                With torch at the night
                <br />
                The crowd of relatives
                <br />
                That stay by their side
                <br/>
                <a style={{fontSize: 18, color: "#777777", fontWeight: "normal"}} href="#" onClick={runNext}>Next</a>
              </Title>
            )}
            {curState === 3 && (
              <Title order={2}>
                Don't try to control
                <br />
                It doesn't have sense
                <br />
                Explore and play
                <br />
                And see how it made
                <br/>
                <a style={{fontSize: 18, color: "#777777", fontWeight: "normal"}} href="#" onClick={runNext}>Close</a>
              </Title>
            )}
          </Container>
        </Html>
      )}
    </group>
  )
}

export default IntroStory
